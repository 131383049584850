<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-card flat>
    <v-navigation-drawer width="100%">
      <v-list-item inactive two-line>
        <v-list-item-avatar>
          <users-avatar :user="$auth.user" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">{{ $auth.user.name }}</v-list-item-title>
          <v-list-item-subtitle class="font-weight-light font-alt">
            Mon espace actuel
            <font-awesome-icon fixed-width size="xs" :icon="['fad', 'chevron-double-right']" class="mr-1 secondary--text" />
            <span class="secondary--text">{{ space.name }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list nav class="pt-0">
        <v-subheader class="pl-2">Mon compte</v-subheader>
        <v-list-item link to="/" exact>
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'ballot']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Mon fil d'actualité</v-list-item-title>
        </v-list-item>
<!--        <v-list-item link @click="goToNotificationsPage">-->
<!--          <v-list-item-icon>-->
<!--            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'bell']" class="grey&#45;&#45;text text&#45;&#45;darken-1" />-->
<!--          </v-list-item-icon>-->
<!--          <v-list-item-title>Mes notifications</v-list-item-title>-->
<!--        </v-list-item>-->
        <v-list-item link @click="openSettings">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'tools']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Mes paramètres</v-list-item-title>
        </v-list-item>
        <v-subheader class="pl-2">Mon espace</v-subheader>
        <v-list-item link @click="openSpaceSelector">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'exchange']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Changer d'espace</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
  import UsersAvatar from '@/modules/core/layout/UserAvatar'
  import {Space} from '@/models'
  export default {
    name: 'UserSideBar',
    components: {UsersAvatar},
    computed: {
      space() {
        return Space.get(this.$store.state.core.space)
      }
    },
    methods: {
      openSpaceSelector() {
        this.$store.commit('layout/toggleSpaceSelector')
      },
      goToNotificationsPage() {
        this.$store.commit('notifications/setPanelOpen', false)
        this.$router.push('/notifications')
      },
      openSettings() {
        this.$store.commit('layout/openSettings')
      }
    }
  }
</script>
