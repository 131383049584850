<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-navigation-drawer v-model="localDrawer" :value="menuDrawerMobile"
                       :width="$vuetify.breakpoint.mdAndDown ? '90%' : '100%'" :class="$vuetify.breakpoint.mdAndDown ? 'navigation-mobile-container' : ''"
                       :height="$vuetify.breakpoint.lgAndUp ? 'auto' : 'calc(100% - 112px)'"
                       :permanent="$vuetify.breakpoint.lgAndUp" :fixed="$vuetify.breakpoint.mdAndDown"
  >
    <v-card flat class="h-100">
      <v-list-item inactive two-line>
        <v-list-item-avatar>
          <users-avatar :user="$auth.user" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ $auth.user.name }}</v-list-item-title>
          <v-list-item-subtitle class="font-weight-light font-alt">
            Mon espace actuel
            <font-awesome-icon fixed-width size="xs" :icon="['fad', 'chevron-double-right']" class="mr-1 secondary--text" />
            <span class="secondary--text">{{ space.name }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />

      <v-list nav class="pt-0">
        <v-subheader class="pl-2">Mon compte</v-subheader>
        <v-list-item link to="/" exact>
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'ballot']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Mon fil d'actualité</v-list-item-title>
        </v-list-item>
        <!--        <v-list-item link :to="{name: 'my-notifications'}">-->
        <!--          <v-list-item-icon>-->
        <!--            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'bell']" class="grey&#45;&#45;text text&#45;&#45;darken-1" />-->
        <!--          </v-list-item-icon>-->
        <!--          <v-list-item-title>Mes notifications</v-list-item-title>-->
        <!--        </v-list-item>-->
        <v-list-item link @click="openSettings">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'tools']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Mes paramètres</v-list-item-title>
        </v-list-item>
        <v-subheader class="pl-2">Mon espace</v-subheader>
        <v-list-item link @click="openSpaceSelector">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'exchange']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Changer d'espace</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="$can('invitations:create', space)" link @click="invite">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'plus']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Inviter un professionnel</v-list-item-title>
        </v-list-item>
        <!--        <v-list-item v-if="$can('members:add_patient', space)" link @click="addPatient">-->
        <!--          <v-list-item-icon>-->
        <!--            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'plus']" class="grey&#45;&#45;text text&#45;&#45;darken-1" />-->
        <!--          </v-list-item-icon>-->
        <!--          <v-list-item-title>Inviter un patient</v-list-item-title>-->
        <!--        </v-list-item>-->
        <v-list-item v-if="$can('invitations:list', space) && space.total_guests" :to="{name: 'space-invitations'}" link exact>
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'users-medical']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Invitations en attente</v-list-item-title>
          <v-spacer />
          <v-chip x-small class="ma-0" color="secondary" text-color="white">{{ space.total_guests }}</v-chip>
        </v-list-item>
        <v-list-item link @click="leaveInfo">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'window-close']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Quitter l'espace</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="$can('update', space)" link @click="openSpaceSettings">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'tools']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Gérer les paramètres</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
  import UsersAvatar from '@/modules/core/layout/UserAvatar'
  import {Space} from '@/models'
  import {mapState} from 'vuex'
  export default {
    name: 'UserSideBar',
    components: {UsersAvatar},
    data() {
      return {
        loading: false
      }
    },
    computed: {
      ...mapState('layout', ['menuDrawerMobile']),
      space() {
        return Space.get(this.$store.state.core.space)
      },
      localDrawer: {
        get() {
          return this.$store.state.layout.menuDrawerMobile
        },
        set(value) {
          this.$store.commit('layout/setDrawerMobile', value)
        }
      }
    },
    methods: {
      openSettings() {
        this.$store.commit('layout/openSettings')
      },
      openSpaceSelector() {
        this.$store.commit('layout/toggleSpaceSelector')
      },
      openSpaceSettings() {
        this.$store.commit('layout/openSpaceSettings')
      },
      invite() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/spaces/components/SpaceProfessionalInvitationModal')
        })
      },
      leaveInfo() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/spaces/components/SpaceLeaveModal')
        })
      },
      addPatient() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/spaces/components/SpaceAddPatientModal')
        })
      }
    }
  }
</script>
